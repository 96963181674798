/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import 'bootstrap-sass/assets/javascripts/bootstrap.js';
import 'jquery';
import '../src/application.scss';

import 'moment/moment'
import 'moment/locale/ja';

import { BigNumber } from 'bignumber.js';
window.BigNumber = BigNumber

import moment from 'moment'
window.moment = moment

import jqueryui from 'jquery-ui';
window.jqueryui = jqueryui

import 'eonasdan-bootstrap-datetimepicker/build/js/bootstrap-datetimepicker.min';
import draggable from 'jquery-ui/ui/widgets/draggable';
import droppable from 'jquery-ui/ui/widgets/droppable';
import sortable from 'jquery-ui/ui/widgets/sortable';
import disableSelection from 'jquery-ui/ui/disable-selection.js';
import effect from 'jquery-ui/ui/effects/effect-highlight.js';

import 'icheck/icheck.min';

$(function() {
  $('input').iCheck({
      checkboxClass: 'icheckbox_flat',
      radioClass: 'iradio_flat',
      increaseArea: '20%' // optional
  });
  // select2 の入力フォームにフォーカスする
  $(document).on('select2:open', function() {
    document.querySelector('.select2-container--open .select2-search__field').focus()
  });
  // datetimepicker の設定
  var datetime = { 'data-date-format': 'YYYY-MM-DD HH:mm' };
  $('.datepicker_datetime').attr(datetime);
  $('.datepicker_datetime').datetimepicker();
  var date = { 'data-date-format': 'YYYY-MM-DD' };
  $('.datepicker_date').attr(date);
  $('.datepicker_date').datetimepicker();
});

import Select2 from "select2";
import 'select2/dist/js/i18n/ja';

// グローバルなオプションの指定
$.fn.select2.defaults.set("allowClear", true);    // 空にできるようにする
$.fn.select2.defaults.set("placeholder", "");     // allowClear だけだとエラーになるため
$.fn.select2.defaults.set("width", "100%");       // tdタグの中に入れたときにだんだん大きくなっていくのを防ぐ
$.fn.select2.defaults.set("theme", "bootstrap");  // テーマの指定
$.fn.select2.defaults.set("language", "ja");      // 言語の指定

// add jQuery function for 'selectbox replacement'
(function($, window) {
  $.fn.replaceOptions = function(options) {
    var self, $option;

    this.empty();
    self = this;

    $.each(options, function(index, option) {
      $option = $("<option></option>")
        .attr("value", option.value)
        .text(option.text);
      self.append($option);
    });
  };
})(jQuery, window);

import 'bootstrap4-toggle/js/bootstrap4-toggle.min.js';
import 'gasparesganga-jquery-loading-overlay/dist/loadingoverlay.min.js';

import toastr from 'toastr/toastr';
window.toastr = toastr;

import Rails from '@rails/ujs';
Rails.start();
